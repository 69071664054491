<template>
  <div
    :class="
      cn(
        'mx-14 flex flex-1 items-center justify-between px-1 md:flex-col md:items-start md:gap-8 lg:mx-0',
        props.class
      )
    "
  >
    <i18n-t
      class="text-2.5xl/10 min-md:whitespace-pre-line font-semibold md:text-2xl/8"
      tag="span"
      keypath="headline"
    >
      <template #risk>
        <LandingHomeChip
          class="w-20 items-center whitespace-pre text-center align-middle transition-all ltr:w-24"
          :color="riskToColor[currentRisk]"
        >
          <UiTransition name="scrollY" duration="duration-500" :delay="{ enter: 'delay-75' }" mode="out-in">
            <span class="block" :key="currentRisk">
              {{ Risk.getRiskLevelText(currentRisk, true) }}
            </span>
          </UiTransition>
        </LandingHomeChip>
      </template>
    </i18n-t>

    <div class="flex md:w-full">
      <SharedServiceCard
        v-for="(item, index) in fundsCards"
        :key="item.id"
        variant="small"
        size="224"
        :classes="{
          root: [
            'shadow-below reversed-shadow-below h-56 w-56 max-w-56 transition-colors duration-300 md:w-full md:flex-1',
            { '-ms-20 md:-ms-10': index > 0 },
          ],
          body: {
            title: 'md:whitespace-pre md:text-sm',
          },
          image: { '-scale-x-100': index > 0 },
        }"
        :item="{
          ...item,
          header: 'Portfolio',
          image: `/homepage/funds/${currentRisk}.png`,
        }"
      >
        <template #footer>
          <div class="flex w-full justify-between">
            <span>{{ t("funds_count", { count: 5 }) }}</span>

            <div class="flex gap-1">
              <span>{{ t("3years") }}</span>
              <UiIconCircleSeparator />
              <UiPositiveNegative
                class="inline-flex"
                :classes="{ success: 'text-[#24DB5F]' }"
                :value="22.834"
                :format="toPercent"
              />
            </div>
          </div>
        </template>
      </SharedServiceCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Risk } from "@finq/app-base/lib/types/risk"

import type { ServiceCardItem } from "@finq/app-base/components/shared/ServiceCard/ServiceCard.vue"

import type { UiCardClasses } from "@finq/ui/components/ui/Card.vue"

const props = defineProps<{
  class?: ClassValue
  classes?: { root?: ClassValue; card?: Partial<UiCardClasses> }
}>()

const { t } = useI18n({ useScope: "local" })
const { tmsafe } = useI18nUtils({ useScope: "local" })

const fundsCards = computed(() => tmsafe("cards") as ServiceCardItem[])

const currentRisk = ref<RiskUnion>(RiskLevel.Three)

const riskToColor = {
  [RiskLevel.One]: "green",
  [RiskLevel.Two]: "emerald",
  [RiskLevel.Three]: "cyan",
  [RiskLevel.Four]: "dark-blue",
  [RiskLevel.Five]: "dark-purple",
} as const

const { counter } = useInterval(6000, { controls: true, immediate: true })
watch(
  () => counter.value,
  (count) => {
    currentRisk.value = ((currentRisk.value % Object.values(RiskLevel).length) + 1) as RiskUnion
  }
)
</script>

<i18n lang="json">
{
  "en": {
    "headline": "Mutual funds or ETFs portfolio?\n Best combination for {risk} level of risk",
    "funds_portfolio": "Portfolio",
    "funds_count": "{count} funds",
    "3years": "3Y",
    "cards": [
      {
        "id": 1,
        "name": "FINQCRAFT ETFs"
      },
      {
        "id": 2,
        "name": "FINQCRAFT Mutual funds"
      }
    ]
  },
  "he": {
    "headline": "תיק קרנות נאמנות או תעודות סל?\n השילוב הטוב ביותר לרמת סיכון {risk}",
    "funds_portfolio": "תיק קרנות",
    "funds_count": "{count} קרנות",
    "3years": "3ש'",
    "cards": [
      {
        "id": 1,
        "name": "FINQCRAFT תעודות סל"
      },
      {
        "id": 2,
        "name": "FINQCRAFT קרנות נאמנות"
      }
    ]
  }
}
</i18n>
