<template>
  <section :class="cn('container flex flex-col gap-10', props.class)" aria-labelledby="home-start-finqing">
    <h2 class="text-2.5xl/8 font-semibold" id="home-start-finqing">{{ t("section_title") }}</h2>

    <LandingHomeSectionStartFinqingFlipCards />

    <div class="flex items-stretch gap-5 md:mt-6 md:flex-col">
      <DataCard
        :title="t('data_cards.finq_full.title')"
        :description="t('data_cards.finq_full.description')"
        :products="['PRODUCT_PENSION', 'PRODUCT_FUND', 'PRODUCT_STOCK']"
      >
        <div class="mx-6 min-h-44">
          <LazyLandingHomeDataCardFINQFull />
        </div>
      </DataCard>

      <DataCard
        :title="t('data_cards.transactions.title')"
        :description="t('data_cards.transactions.description')"
        :products="['PRODUCT_FUND', 'PRODUCT_STOCK']"
      >
        <div class="flex min-h-44 justify-center">
          <LazyLandingHomeDataCardStocksTransactions />
        </div>
      </DataCard>

      <DataCard
        :title="t('data_cards.finq_risk.title')"
        :description="t('data_cards.finq_risk.description')"
        :products="['PRODUCT_PENSION', 'PRODUCT_FUND', 'PRODUCT_STOCK']"
      >
        <div class="mx-auto flex min-h-44 w-fit flex-col gap-4">
          <LandingHomeChip
            class="min-w-20 justify-center text-center ltr:min-w-24"
            v-for="risk in Object.values(RiskLevel).reverse()"
            :key="risk"
            :color="riskToColor[risk]"
          >
            {{ Risk.getRiskLevelText(risk) }}
          </LandingHomeChip>
        </div>
      </DataCard>
    </div>
  </section>
</template>

<script setup lang="tsx">
import type { HTMLAttributes } from "vue"

import { QLogoIcon } from "#components"
import { PricingProductEnum } from "#imports"

import { Risk } from "@finq/app-base/lib/types/risk"

import type { ChipProps } from "@finq/app-base/components/landing/home/Chip.vue"

const props = defineProps<{
  class?: ClassValue
}>()

const { t } = useI18n({ useScope: "local" })

const riskToColor: Record<RiskUnion, ChipProps["color"]> = {
  [RiskLevel.One]: "green",
  [RiskLevel.Two]: "emerald",
  [RiskLevel.Three]: "cyan",
  [RiskLevel.Four]: "dark-blue",
  [RiskLevel.Five]: "dark-purple",
} as const

const { isProductExist } = useProducts()

const DataCard: FunctionalComponent<
  { class?: ClassValue; title: string; description: string; products?: PricingProductEnum[] } & HTMLAttributes
> = ({ class: className, title, description, products, ...rest }, { slots }) => {
  const availableProducts = computed(() => products?.filter(isProductExist) ?? [])

  return (
    <div
      class={cn(
        "start-finqing-data-card relative flex min-h-96 w-full flex-1 flex-col gap-8 rounded-lg bg-neutral-100 p-5",
        className
      )}
      {...rest}
    >
      <span class="font-medium text-black/50">{title}</span>

      {slots.default ? slots.default() : null}

      <div class="mt-auto flex flex-col gap-4">
        {availableProducts.value.length > 0 && (
          <div class="flex">
            {availableProducts.value.map((product, index) => (
              <QLogoIcon
                size="small"
                shadow="none"
                outline="white"
                class={cn({ "-ms-1.5": index > 0 })}
                productId={product}
                style={{ zIndex: availableProducts.value.length - index }}
              />
            ))}
          </div>
        )}

        <p class="text-sm font-light text-black/50 md:text-base">{description}</p>
      </div>
    </div>
  )
}
</script>

<style lang="scss">
@keyframes startFinqingAnimateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.start-finqing-gradient {
  background: conic-gradient(
    from 90deg at 50% 50%,
    #f9c 3deg,
    #ffa299 18deg,
    #ffbf80 36deg,
    #eff986 90deg,
    #5ae2a9 169deg,
    #4db5ff 205deg,
    #df80ff 331deg
  );
  background-size: 150% 150%;
  animation: startFinqingAnimateGradient 10s linear infinite;
}

.start-finqing-gradient-text-settings {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.start-finqing-data-card {
  height: 480px;
}
</style>

<i18n lang="json">
{
  "he": {
    "section_title": "FINQING מחוץ לקופסא",
    "data_cards": {
      "transactions": {
        "title": "פעולות",
        "description": "כל החלטה על עסקה שהתקבלה בעבר על ידי מנוע FINQ-AI הכוללת קנייה ומכירה של מניות, זמינה בהיסטוריה של התיק. "
      },
      "finq_full": {
        "title": "FINQFULL",
        "description": "דירוג יחסי ורציף של כל המוצרים הפיננסיים מאותו הסוג, המאפשר לראות את כל התמונה בכל יום מחדש."
      },
      "finq_risk": {
        "title": "FINQ RISK",
        "description": "FINQ מעריכה את רמת סיכון של כל מוצר פיננסי באמצעות גישה ייחודית לנתונים המפרקת כל מוצר לנכסיו הבודדים, ובאמצעות מודלים של Big Data, מחשבת את הסיכון הריאלי שלו מלמטה למעלה על בסיס יומי."
      }
    }
  },
  "en": {
    "section_title": "FINQING OUTSIDE THE BOX",
    "data_cards": {
      "transactions": {
        "title": "TRANSACTIONS",
        "description": "Every transaction decision ever made by our FINQ-AI, which includes buying and selling stocks, is available at your disposal in the portfolio's history."
      },
      "finq_full": {
        "title": "FINQFULL",
        "description": "A relative and continuous ranking of all financial products of the same asset type, which lets you see the whole picture every day anew."
      },
      "finq_risk": {
        "title": "FINQ RISK",
        "description": "FINQ assesses financial product risk daily using a data-driven approach that breaks down each product into single assets and, using Big Data models, calculates  bottom-up its actual risk."
      }
    }
  }
}
</i18n>
