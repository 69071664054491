<template>
  <div :class="cn('flex flex-col gap-6 md:gap-4', props.class)">
    <div class="flex flex-col gap-3">
      <h2 class="font-medium text-black/50" :id="labelledBy">{{ header }}</h2>
      <h3 class="text-2.5xl/8 font-semibold">{{ title }}</h3>
    </div>

    <UiLink
      class="text-primary group flex w-fit items-center gap-px text-sm/6 underline-offset-4 hover:underline md:text-base"
      :href="seeAllHref"
    >
      <span>{{ $t("common.see_all") }}</span>
      <LucideChevronLeft
        class="inline-flex size-3.5 transition-all group-hover:-translate-x-1 ltr:rotate-180 ltr:group-hover:translate-x-1"
      />
    </UiLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  class?: ClassValue
  labelledBy?: string
  header: string
  title: string
  seeAllHref: string
}>()
</script>
