<template>
  <div
    ref="containerRef"
    :class="
      cn(
        'mx-14 flex flex-1 items-center justify-between px-1 md:flex-col md:items-start md:gap-8 lg:mx-0',
        props.class
      )
    "
  >
    <i18n-t class="text-2.5xl/7 w-full pe-10 font-semibold md:hidden" tag="span" keypath="headline">
      <template #long>
        <LandingHomeChip color="blue">{{ t("long10")?.toLowerCase() }}</LandingHomeChip>
      </template>

      <template #short>
        <LandingHomeChip color="purple">{{ t("short10")?.toLowerCase() }}</LandingHomeChip>
      </template>

      <template #neutral>
        <LandingHomeChip color="orange"> {{ t("longShort20")?.toLowerCase() }}? </LandingHomeChip>
      </template>
    </i18n-t>

    <i18n-t
      class="text-2.5xl/7 hidden w-full pe-10 font-semibold md:block md:text-2xl/7"
      tag="div"
      keypath="mobile_headline"
    >
      <template #type>
        <LandingHomeChip
          v-if="currentPortfolioChip"
          :color="currentPortfolioChip.color"
          :text="currentPortfolioChip.text?.toLowerCase()"
        />
      </template>
    </i18n-t>

    <div :class="cn('grid-cols-auto-fill flex w-full justify-end gap-0')">
      <UiTransition name="fade" mode="out-in">
        <LandingHomeMovingCardsList
          :list="types"
          ref="movingCardsListRef"
          :key="isFetched ? 'types' : 'skeletons'"
        >
          <template #item="{ item, className }">
            <StocksPortfoliosTypeCard
              :item="item"
              full
              :loading="!isFetched"
              :error="isError"
              variant="small"
              size="224px"
              :classes="{
                root: [
                  className,
                  'shadow-below reversed-shadow-below z-1 -ms-24 max-h-56 max-w-56 flex-1 first:ms-0 md:-ms-[65%]',
                ],
              }"
            >
              <template #footer>
                <div class="flex w-full flex-col gap-2" v-if="isFetched">
                  <div
                    v-for="stockData in generateStockYieldsData(item)"
                    :key="stockData.key"
                    :class="cn('relative flex w-full items-center justify-between whitespace-pre last:pt-2')"
                  >
                    <div
                      class="dotted-divider absolute inset-x-0 top-0 opacity-40"
                      v-if="stockData.id === 'overperformance'"
                    ></div>
                    <UiSkeleton
                      :classes="{
                        slot: 'reverse md:gap-xxs prose:leading-none flex md:flex-row md:items-center',
                        line: 'w-l',
                      }"
                      :loading="!isFetched"
                    >
                      <span
                        :class="
                          cn('font-light text-white', { 'font-medium': stockData.id === 'overperformance' })
                        "
                      >
                        {{ stockData.key }}

                        <span
                          class="ms-px text-[8px] font-light leading-none"
                          v-if="stockData.id === 'overperformance'"
                        >
                          {{ t("since_inception") }}
                        </span>
                      </span>
                    </UiSkeleton>

                    <UiSkeleton
                      :loading="!isFetched"
                      :classes="{
                        slot: 'md:gap-xxs prose:leading-none flex flex-col-reverse md:flex-row md:items-center',
                        line: 'w-l',
                      }"
                    >
                      <UiPositiveNegative
                        :classes="{ success: 'text-[#24DB5F]', span: 'font-medium' }"
                        :value="stockData.value || 0"
                        :format="(v) => toPercent(v, false, 2)"
                      />
                    </UiSkeleton>
                  </div>
                </div>
              </template>
            </StocksPortfoliosTypeCard>
          </template>
        </LandingHomeMovingCardsList>
      </UiTransition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LandingHomeMovingCardsList } from "#components"
import type { ComponentExposed } from "vue-component-type-helpers"

import {
  type MappedStocksPortfolioType,
  PortfolioType,
  type StocksPortfolioTypeWithAdditional,
  mapListToTypedCards,
} from "@finq/stocks/types/stocks-portfolios"
import type { UiCardClasses } from "@finq/ui/components/ui/Card.vue"

const props = defineProps<{
  class?: ClassValue
  classes?: { root?: ClassValue; card?: Partial<UiCardClasses> }
}>()

const { isIntersected, containerRef } = useIsIntersected()

const { data, isFetched, isError } = useStocksPortfoliosTypes({
  enabled: isIntersected,
})

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })

const movingCardsListRef =
  ref<ComponentExposed<typeof LandingHomeMovingCardsList<MappedStocksPortfolioType>>>()

const reorderedListLastItem = computed(() => {
  return movingCardsListRef.value?.reorderedList?.at(-1)
})

const types = computed(() => {
  if (isError.value || !isFetched.value) {
    return arrayInLength(3).map((i: number) => ({ id: String(i) })) as MappedStocksPortfolioType[]
  }

  const list = mapListToTypedCards(data.value) as MappedStocksPortfolioType[]

  return list
    .map((item) => {
      return { ...item, name: portfolioTypeNames[item.id], description: t(`descriptions.${item.id}`) }
    })
    .slice(0, -1)
})

const portfolioTypeNames = {
  [PortfolioType.Long]: "FINQFIRST S&P",
  [PortfolioType.Short]: "FINQLAST S&P",
  [PortfolioType.LongShort]: "FINQNEUTRAL S&P",
  [PortfolioType.Snp]: "FINQNEUTRAL",
} as const

const chipColors = {
  [PortfolioType.Long]: "blue",
  [PortfolioType.Short]: "purple",
  [PortfolioType.LongShort]: "orange",
  [PortfolioType.Snp]: "orange",
} as const

const currentPortfolioChip = computed(() => {
  if (!reorderedListLastItem?.value) return null

  return {
    text: t(reorderedListLastItem?.value.id),
    color: chipColors[reorderedListLastItem?.value.id],
  }
})

const { counter, resume } = useInterval(6000, { controls: true, immediate: false })

watch(
  () => counter.value,
  () => {
    movingCardsListRef.value?.reorderLastToFirst()
  }
)

watchEffect(() => {
  if (isFetched.value) resume()
})

function generateStockYieldsData(stock: StocksPortfolioTypeWithAdditional) {
  const { id, comparedPortfolioId, accumulatedYield, comparedAccumulatedYield } = stock
  if (!id) return []
  return [
    {
      id,
      key: $t("stocks.portfolios.types." + id),
      value: accumulatedYield,
    },
    {
      id: comparedPortfolioId,
      key: $t("stocks.portfolios.types." + comparedPortfolioId),
      value: comparedAccumulatedYield || 0,
    },
    {
      id: "overperformance",
      key: t("overperformance"),
      value: (accumulatedYield || 0) - (comparedAccumulatedYield || 0),
    },
  ]
}
</script>

<style lang="scss" scoped>
.dotted-divider {
  height: 1px;
  background-image: linear-gradient(to right, currentColor 1px, transparent 1px);
  background-size: 4px 1px;
  background-repeat: repeat-x;
}
</style>

<i18n lang="json">
{
  "en": {
    "headline": "Choose {long} or {short} or maybe {neutral}",
    "mobile_headline": "Choose {type}",
    "description": "AI-based stocks portfolios with three distinct strategies, built to outperform the S&P",
    "plans_count": "{count} plans",
    "long10": "Long",
    "short10": "Short",
    "longShort20": "Neutral",
    "descriptions": {
      "long10": "Our top 10 stocks to buy",
      "short10": "Our top 10 stocks to short-sell",
      "longShort20": "Our top 20 stocks for market-neutral position"
    },
    "overperformance": "Overperformance",
    "since_inception": "since inception"
  },
  "he": {
    "headline": "אסטרטגיית {long} או {short} או אולי {neutral}",
    "mobile_headline": "אסטרטגיית {type}",
    "description": "תיקי מניות מבוססי בינה מלאכותית עם שלוש אסטרטגיות ייחודיות שנבנו כדי לייצר תשואה גבוהה ממדד ה-S&P",
    "plans_count": "{count} תוכניות",
    "long10": "לונג",
    "short10": "שורט",
    "longShort20": "נייטרלית",
    "descriptions": {
      "long10": "עשרת המניות המומלצות לקניה",
      "short10": "עשרת המניות המומלצות למכירה בחסר",
      "longShort20": "עשרת המניות המובילות לאסטרטגיית שוק נייטרלית"
    },
    "overperformance": "ביצועי יתר",
    "since_inception": "מתאריך ההקמה"
  }
}
</i18n>
