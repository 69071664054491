<template>
  <UiCard v-if="error" v-bind="cardAttributes">
    <UiNoData
      :config="tmsafe('stocks.no_data')"
      :classes="{ title: 'text-base font-semibold leading-normal', text: 'm-0' }"
    />
  </UiCard>
  <UiCard v-else v-bind="cardAttributes" border="none">
    <div class="pointer-events-none absolute inset-0 z-0" :style="backgroundVariants({ backgroundColor })" />

    <span :class="cn('z-1 text-start text-xs font-light leading-5 text-black/30', classes?.smallHeader)">
      <b class="font-medium">FINQ</b>FULL
    </span>

    <SharedGradientShadowedText
      class="absolute left-1/2 top-10 -translate-x-1/2 font-semibold"
      :style="{ top: variant === 'small' ? '20%' : '20%' }"
      :rank="rankBackground"
      :variant
    >
      {{ rank }}
    </SharedGradientShadowedText>

    <div class="mt-auto">
      <slot />
    </div>
  </UiCard>
</template>

<script setup lang="ts">
import type { StyleHTMLAttributes } from "vue"

import { type StyleVariantProps, sva } from "style-variance-authority"

import { Risk, RiskLevel } from "@finq/app-base/lib/types/risk"

import { UiCardClasses, UiCardProps } from "@finq/ui/components/ui/Card.vue"

export interface UiRankCardClasses extends UiCardClasses {
  smallHeader?: ClassValue
}

const props = withDefaults(
  defineProps<{
    error?: boolean
    class?: ClassValue
    classes?: Partial<UiRankCardClasses>
    rank?: number
    loading?: boolean
    backgroundColor?: StyleVariantProps<typeof backgroundVariants>["backgroundColor"]
    rankBackground?: StyleVariantProps<typeof backgroundVariants>["backgroundColor"]
    variant?: "default" | "small"
  }>(),
  { variant: "default", rank: 1 }
)

const { tmsafe } = useI18nUtils()

const cardAttributes = computed((): Partial<UiCardProps> & StyleHTMLAttributes => {
  return {
    border: "none",
    classes: {
      ...props.classes,
      root: cn(
        "rank-card relative h-full w-full rounded-lg bg-white md:h-full md:max-w-none",
        props.classes?.root,
        props.class,
        { "rank-card--small": props.variant === "small" }
      ),
      body: cn([
        "flex w-full flex-col p-4",
        { "p-2.5": props.variant === "small" },
        props.classes?.body,
        props.classes?.body,
      ]),
    },
  }
})

const backgroundVariants = sva({
  defaultVariants: { backgroundColor: "gray" },
  variants: {
    backgroundColor: {
      gray: { backgroundImage: "linear-gradient(0deg, #F7F7F7 0%, #FAFAFA 60%, #FBFBFB 100%)" },
      "1": { backgroundImage: Risk.BACKGROUND_GRADIENTS[RiskLevel.One] },
      "2": { backgroundImage: Risk.BACKGROUND_GRADIENTS[RiskLevel.Two] },
      "3": { backgroundImage: Risk.BACKGROUND_GRADIENTS[RiskLevel.Three] },
      "4": { backgroundImage: Risk.BACKGROUND_GRADIENTS[RiskLevel.Four] },
      "5": { backgroundImage: Risk.BACKGROUND_GRADIENTS[RiskLevel.Five] },
    },
  },
})
</script>

<style lang="scss" scoped>
.rank-card {
  height: 17.5rem;
  width: 14rem;

  &--small {
    height: 9rem;
    width: 9rem;
  }

  &__button {
    background: rgba(0, 0, 0, 0.3);
  }
}

.rank-card-rank {
  font-size: 8rem;
  line-height: 0.8;
}

.rank-card-rank-shadow {
  background-color: #565656;
  color: transparent;
  text-shadow: 0.5px 0.5px 1.25px rgba(255, 255, 255, 0.75);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  mix-blend-mode: overlay;
  opacity: 0.2;
}

.mask-to-transparent {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0) 100%);
}
</style>
