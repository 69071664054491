<template>
  <UiLink
    :class="
      cn(
        'article-card swiper-card group flex h-80 flex-col overflow-hidden rounded-lg bg-white transition-shadow',
        props.class
      )
    "
    :to="article.href"
    dir="ltr"
  >
    <NuxtImg
      class="h-32 w-full rounded-t-lg object-cover"
      :src="getImageUrl(article.image)"
      :alt="article.title"
      loading="lazy"
    />

    <div class="flex flex-1 flex-col p-5">
      <div class="flex flex-col gap-2">
        <span class="text-xs/5 text-black/50">{{ article.date }}</span>
        <p class="line-clamp-2 text-lg/6 font-semibold">{{ article.title }}</p>
      </div>

      <div
        class="mt-top mt-auto flex items-center justify-between text-xs underline-offset-4 group-hover:underline"
      >
        <span class="text-black/50">
          Read on <span class="text-primary">{{ article.site }} </span>
        </span>

        <LucideArrowLeft
          class="inline-flex transition-all group-hover:translate-x-1 ltr:rotate-180 ltr:group-hover:translate-x-1"
          :size="14"
        />
      </div>
    </div>
  </UiLink>
</template>

<script setup lang="ts">
import type { NewsroomArticle } from "./Newsroom.vue"

const props = defineProps<{
  class?: ClassValue
  article: NewsroomArticle
}>()

const { t } = useI18n({ useScope: "local" })
</script>

<style lang="scss" scoped>
.article-card {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 5px 12px -2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow:
      0px 0px 0px 1px rgba(0, 0, 0, 0.01),
      0px 5px 12px -2px rgba(0, 0, 0, 0.1);
  }
}
</style>
