<template>
  <div :class="cn('container flex flex-col gap-10 md:gap-6', props.class)">
    <h2 class="text-2.5xl/8 font-semibold" id="home-start-finqing">{{ t("section_title") }}</h2>

    <!-- Desktop Cards -->
    <div class="grid grid-cols-2 gap-5 md:hidden">
      <div
        class="reason-card flex items-center gap-10 rounded-lg px-20 py-14 transition-colors duration-1000 lg:gap-5 lg:px-10"
        v-for="(item, index) in list"
        :style="{
          background: COLORS[index % COLORS.length].bg,
        }"
        :key="item.text"
      >
        <NuxtImg class="mx-4 aspect-square size-16" :src="getImageUrl(item.icon)" loading="lazy" />

        <p
          class="my-1 whitespace-pre-line text-lg/6 font-medium text-black/30 [&>strong]:text-black/50"
          v-html-format="item.text"
          :style="{ color: COLORS[index % COLORS.length].color }"
        />
      </div>
    </div>

    <!-- Mobile switchable cards -->
    <SwiperList
      class="relative hidden md:flex"
      :list
      gap="gap-0"
      item-width="100dvw"
      :options="swiperOptions"
      :enable="isMobile"
      :classes="{ fallback: { root: 'md:ps-0' } }"
      @slide-change="(v) => (currentItemIndex = v.realIndex)"
    >
      <template #item="{ item, index, className }">
        <div
          :class="['hidden h-80 flex-col gap-8 p-5 transition-colors duration-500 md:flex', className]"
          :style="{ background: COLORS[index % COLORS.length].bg }"
        >
          <NuxtImg class="block aspect-square size-10" :src="getImageUrl(item.icon)" loading="lazy" />
          <p
            class="whitespace-pre-line text-lg/6 font-medium text-black/30 md:text-xl [&>strong]:text-black/50"
            v-html-format="item.text"
          />
        </div>
      </template>

      <!-- Navigation buttons -->
      <div class="z-2 absolute bottom-5 end-5 mt-auto flex flex-row-reverse gap-2 self-end">
        <UiButton
          class="reason-navigate-next size-7 rounded-full bg-black/30 p-1 text-white hover:text-white/80 disabled:border-black/10 disabled:bg-transparent disabled:text-black/30"
          variant="icon"
        >
          <LucideArrowLeft class="ltr:rotate-180" />
          <div class="sr-only">{{ $t("common.next") }}</div>
        </UiButton>

        <UiButton
          class="reason-navigate-prev size-7 rounded-full bg-black/30 p-1 text-white hover:text-white/80 disabled:border-black/10 disabled:bg-transparent disabled:text-black/30"
          variant="icon"
        >
          <LucideArrowLeft class="rtl:rotate-180" />
          <div class="sr-only">{{ $t("common.prev") }}</div>
        </UiButton>
      </div>
    </SwiperList>
  </div>
</template>

<script setup lang="ts">
import { register } from "swiper/element/bundle"
import type { SwiperOptions } from "swiper/types"

register()

interface Reason {
  icon: string
  text: string
}

const props = defineProps<{
  class?: ClassValue
}>()

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })
const { tmsafe } = useI18nUtils({ useScope: "local" })

const { isMobile } = useDisplay()
const { preloadImage } = usePreloadImage()

const list = computed(() => tmsafe("reasons") as Reason[])
const currentItemIndex = ref(0)

watch(isMobile, () => (currentItemIndex.value = 0))
watch(currentItemIndex, preloadNextImage)
onMounted(preloadNextImage)

const COLORS = [
  {
    bg: "#FFFBEC",
    border: "1px solid rgba(0, 0, 0, 0.03)",
  },
  {
    bg: "#F5FDED",
    border: "1px solid rgba(0, 0, 0, 0.03)",
  },
  {
    bg: "#F2FAFC",
    border: "1px solid rgba(0, 0, 0, 0.03)",
  },
  {
    bg: "#F0FDF6",
    border: "1px solid rgba(0, 0, 0, 0.03)",
  },
]

function preloadNextImage() {
  const nextIndex = currentItemIndex.value + 1
  if (nextIndex >= list.value.length) return

  preloadImage(list.value[nextIndex].icon)
}

const swiperOptions = computed((): SwiperOptions => {
  return {
    slidesPerView: 1,
    slidesPerGroup: 1,
    navigation: {
      prevEl: ".reason-navigate-prev",
      nextEl: ".reason-navigate-next",
    },
    breakpoints: {
      0: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
  }
})
</script>

<style lang="scss" scoped>
.override-width {
  width: calc(100% + 2.5rem);
}
.reason-card {
  min-height: 216px;
  max-height: 216px;
}
</style>

<i18n lang="json">
{
  "he": {
    "section_title": "מה FINQERS מקבלים?",
    "reasons": [
      {
        "icon": "homepage/reasons/scales.png",
        "text": "הטכנולוגיה מבוססת הבינה המלאכותית שלנו מבטיחה 100% אובייקטיביות ומסירה את ההטיות של המתווכים הפיננסים שלא תמיד פועלים עבור האינטרס שלך."
      },
      {
        "icon": "homepage/reasons/coins.png",
        "text": "מוצרים שניבנו כדי להשיג תשואות גבוהות יותר מאסטרטגיות השקעה מסורתיות ומהמדדים המובילים בעולם."
      },
      {
        "icon": "homepage/reasons/clock.png",
        "text": "יצירת פרופיל פיננסי לכל רמת סיכון על מנת להתאים מוצרי השקעה לרמת הנוחות שלך באמצעות השקעה מונחית סיכון. "
      },
      {
        "icon": "homepage/reasons/check.png",
        "text": "מנוע מבוסס AI המנטר את כל השוק מסביב לשעון ומבטיח חשיפה להזדמנויות השקעה חדשות 24/7."
      }
    ]
  },
  "en": {
    "section_title": "WHAT DO FINQERS GET?",
    "reasons": [
      {
        "icon": "homepage/reasons/scales.png",
        "text": "Our AI-based technology ensures **100% objectivity** by eliminating the biases of middlemen who don’t always act in your interest."
      },
      {
        "icon": "homepage/reasons/coins.png",
        "text": "Products built to outperform traditional investing strategies and the world’s leading indices result in **better returns.**"
      },
      {
        "icon": "homepage/reasons/clock.png",
        "text": "An AI-engine monitors the entire market 24/7, ensuring you're always aware of investment opportunities with **24/7 market tracking.**"
      },
      {
        "icon": "homepage/reasons/check.png",
        "text": "Creating financial profiles for various risk levels to match investment products that suit your comfort level with **risk-guided investing.**"
      }
    ]
  }
}
</i18n>
