<template>
  <section
    :class="cn('container flex flex-col items-center gap-6 text-center', props.class)"
    aria-labelledby="outro-heading"
    :key="cta.label ? cta.label : 'cta'"
  >
    <div class="flex flex-col gap-1">
      <span class="whitespace-pre-line text-xl text-black/50">
        {{ t("small_title") }}
      </span>

      <h2 class="text-4.5xl font-semibold" id="outro-heading" v-html-format="t('title')" />
    </div>

    <UiButton v-bind="cta.action" :id="genClickId('index', 'outro', 'button')">
      {{ cta.label }}
    </UiButton>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  class?: ClassValue
}>()

const { t } = useI18n({ useScope: "local" })
const cta = useHomepageCta()
</script>

<i18n lang="json">
{
  "he": {
    "small_title": "כולם יכולים",
    "title": "להשקיע חכם"
  },
  "en": {
    "small_title": "Anyone can become a smart investor",
    "title": "Let science\n:md make you money"
  }
}
</i18n>
